import { Options } from '@b2chat/chat-center-sdk';
import B2ChatClient from '@client-sdk';
import { appConfig } from '@src/config/appConfig';
import {
  fetchChatsOnHold,
  fetchChatsOnHoldFailure,
  fetchChatsOnHoldFulfill,
  fetchChatsOnHoldSuccess,
  fetchChatsRequest,
  fetchChatsRequestFailure,
  fetchChatsRequestFulfill,
  fetchChatsRequestSuccess,
  fetchCRMbutton,
  fetchCRMbuttonFailure,
  fetchCRMbuttonSuccess,
  fetchCRMinfo,
  fetchCRMinfoFailure,
  fetchCRMinfoSuccess,
  fetchNotificationTimeLimit,
  fetchNotificationTimeLimitFailure,
  fetchSearchChats,
  fetchSegments,
  fetchSegmentsFailure,
  fetchSegmentsSuccess,
  fetchTransformTextAI,
  fetchTransformTextAIFailure,
  fetchTransformTextAISuccess,
  increaseOffsetChatsOnHold,
  increaseOffsetChatsRequest,
  setNotificationTimeLimit,
} from '@src/reducers/chats';
import {
  ActionMode,
  ChatsState,
  initialTimeLimit,
  NotificationTimeLimit,
  Segments,
} from '@src/reducers/chats/initialState';
import { chatSearchActions } from '@src/reducers/chatSearch';
import {
  isFirstTrayChatsOnHoldSelector,
  isFirstTrayChatsRequestSelector,
  totalChatsOnHoldSelector,
  totalChatsRequestSelector,
} from '@src/selectors/chats';
import {
  activeSideFilters,
  filtersToRequestPayloadSelector,
} from '@src/selectors/chatSearch';
import {
  ChatSideType,
  ChatsResponse,
  NotificationType,
  ResponseStatus,
} from '@src/types/chats';
import { ErrorData, RootState } from '@types';
import { B2ChatAPI } from '@types-api';
import {
  call,
  debounce,
  fork,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

function* startFetchChatsOnHold() {
  const filtersPayload: Record<string, unknown> = yield select(
    filtersToRequestPayloadSelector
  );
  const options: Options = yield select(state => ({
    params: {
      limit: state.chats.chatsOnHold.limit,
      offset: state.chats.chatsOnHold.offset,
    },
    data: {
      type: ChatSideType.ON_HOLD,
      ...filtersPayload,
    },
  }));

  let data;
  let responseError;

  try {
    const response: B2ChatAPI.Response<ChatsResponse> = yield call(
      B2ChatClient.resources.agentConsole.actions.chats,
      options
    );

    data = response.data;
    responseError = response.error;

    // Note: hotfix, sometimes the api returns 500 error with data
    if (responseError && !data) {
      yield put(fetchChatsOnHoldFailure(responseError));
    } else if (data) {
      const { totalChats = 0, filteredChats: tray } = data;
      const totalChatsOnHold: number = yield select(totalChatsOnHoldSelector);
      const isFirstTrayChatsOnHold: boolean = yield select(
        isFirstTrayChatsOnHoldSelector
      );
      yield put(
        fetchChatsOnHoldSuccess({
          tray,
          activeChat: appConfig.getData('activeChat'),
          totalFilteredChatsOnHold: totalChats,
          totalChatsOnHold: isFirstTrayChatsOnHold
            ? totalChats
            : totalChatsOnHold,
        })
      );
    }
  } catch (error) {
    if (data) {
      const { totalChats = 0, filteredChats: tray } = data;
      const totalChatsOnHold: number = yield select(totalChatsOnHoldSelector);
      const isFirstTrayChatsOnHold: boolean = yield select(
        isFirstTrayChatsOnHoldSelector
      );
      yield put(
        fetchChatsOnHoldSuccess({
          tray,
          activeChat: appConfig.getData('activeChat'),
          totalFilteredChatsOnHold: totalChats,
          totalChatsOnHold: isFirstTrayChatsOnHold
            ? totalChats
            : totalChatsOnHold,
        })
      );
    } else {
      yield put(fetchChatsOnHoldFailure(error as ErrorData));
    }
  } finally {
    yield put(fetchChatsOnHoldFulfill());
  }
}

function* fetchNotificationTimeLimitSaga(
  action: ReturnType<typeof fetchNotificationTimeLimit>
) {
  const { mode, notificationId, limit, status, type } = action.payload;
  let data;
  if (mode === ActionMode.UPDATE) {
    data = { status, noResponseTimeLimit: limit };
  }

  if (mode === ActionMode.CREATE) {
    data = { status, noResponseTimeLimit: limit, type };
  }

  try {
    const response: B2ChatAPI.Response<NotificationTimeLimit[]> = yield call(
      [B2ChatClient.resources.notification.actions, mode],
      {
        params:
          mode === ActionMode.UPDATE ? { id: notificationId || '' } : undefined,
        data,
        transformResponse: notification =>
          mode === ActionMode.GET
            ? JSON.parse(notification)
            : [JSON.parse(notification)],
      }
    );

    if (response.error && !response.data) {
      yield put(fetchNotificationTimeLimitFailure());
    } else {
      const timeLimit = response.data.find(
        item => item.type === NotificationType.NO_RESPONSE_TIME_LIMIT
      );
      const notification = timeLimit || initialTimeLimit;
      const loading =
        mode !== ActionMode.GET
          ? ResponseStatus.UPDATED
          : ResponseStatus.SUCCESS;
      yield put(setNotificationTimeLimit({ ...notification, loading }));
    }
  } catch (error) {
    yield put(fetchNotificationTimeLimitFailure());
  }
}

function* fetchCRMbuttonSaga(action: ReturnType<typeof fetchCRMbutton>) {
  const chatId = action.payload;
  try {
    const response: B2ChatAPI.Response<boolean> = yield call(
      B2ChatClient.resources.chats.actions.share,
      { params: { chatId } }
    );
    if (response.error) {
      yield put(fetchCRMbuttonFailure());
    } else {
      yield put(fetchCRMbuttonSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchCRMbuttonFailure());
  }
}

function* fetchCRMinfoSaga(action: ReturnType<typeof fetchCRMinfo>) {
  const chatId = action.payload;
  try {
    const response: B2ChatAPI.Response<boolean> = yield call(
      B2ChatClient.resources.chats.actions.send,
      {
        params: {
          chatId,
        },
      }
    );
    if (response.error) {
      yield put(fetchCRMbuttonFailure());
    } else {
      yield put(fetchCRMinfoSuccess());
    }
  } catch (error) {
    yield put(fetchCRMinfoFailure());
  }
}

function* startFetchChatsRequestSaga() {
  const filtersPayload: Record<string, unknown> = yield select(
    filtersToRequestPayloadSelector
  );
  const options: Options = yield select(state => ({
    params: {
      limit: state.chats.chatsRequest.limit,
      offset: state.chats.chatsRequest.offset,
    },
    data: {
      type: ChatSideType.REQUEST,
      ...filtersPayload,
    },
  }));

  let data;
  let responseError;

  try {
    const response: B2ChatAPI.Response<ChatsResponse> = yield call(
      B2ChatClient.resources.agentConsole.actions.chats,
      options
    );

    data = response.data;
    responseError = response.error;

    // Note: hotfix, sometimes the api returns 500 error with data
    if (responseError && !data) {
      yield put(fetchChatsRequestFailure(responseError));
    } else if (data) {
      const { totalChats = 0, filteredChats: tray } = data;
      const totalChatRequests: number = yield select(totalChatsRequestSelector);
      const isFirstTrayChatsRequest: boolean = yield select(
        isFirstTrayChatsRequestSelector
      );
      yield put(
        fetchChatsRequestSuccess({
          tray,
          totalFilteredChatRequests: totalChats,
          totalChatRequests: isFirstTrayChatsRequest
            ? totalChats
            : totalChatRequests,
        })
      );
    }
  } catch (error) {
    if (data) {
      const { totalChats = 0, filteredChats: tray } = data;
      const totalChatRequests: number = yield select(totalChatsRequestSelector);
      const isFirstTrayChatsRequest: boolean = yield select(
        isFirstTrayChatsRequestSelector
      );
      yield put(
        fetchChatsRequestSuccess({
          tray,
          totalFilteredChatRequests: totalChats,
          totalChatRequests: isFirstTrayChatsRequest
            ? totalChats
            : totalChatRequests,
        })
      );
    } else {
      yield put(fetchChatsRequestFailure(error as ErrorData));
    }
  } finally {
    yield put(fetchChatsRequestFulfill());
  }
}

function* fetchTransformTextAISaga(
  action: ReturnType<typeof fetchTransformTextAI>
) {
  let inputText: string = yield select(
    state => state.chats.writtenMessages[state.chats.activeChat.id]
  );

  if (inputText.trim().length > 0) {
    sessionStorage.setItem('inputText', inputText);
  } else {
    inputText = sessionStorage.getItem('inputText') || '';
  }

  try {
    const {
      language = 'spanish',
      command,
      apiName = 'claude',
    } = action.payload;

    const response: B2ChatAPI.Response<{
      outputMessage: string;
      outputText: string;
    }> = yield call(B2ChatClient.resources.ai.actions.redact, {
      params: { language, command, apiName },
      data: {
        inputText,
      },
    });

    if (response.data) {
      yield put(fetchTransformTextAISuccess(response.data.outputText));
    }
    if (response.error || response.data.outputMessage) {
      throw new Error('Fail to transform text with AI');
    }
  } catch (error: unknown) {
    yield put(
      fetchTransformTextAIFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Transform text with AI',
      })
    );
  }
}

function* fetchSegmentsSaga() {
  try {
    const { error, data }: B2ChatAPI.Response<Segments[]> = yield call(
      B2ChatClient.resources.segments.actions.get
    );
    if (error) {
      yield put(fetchSegmentsFailure());
    } else {
      yield put(fetchSegmentsSuccess(data));
    }
  } catch (error) {
    yield put(fetchSegmentsFailure());
  }
}

function* fetchStartConsoleSaga() {
  yield call(fetchSegmentsSaga);
  yield fork(startFetchChatsOnHold);
  yield fork(startFetchChatsRequestSaga);
}

function* fetchChatsOnHoldSaga() {
  yield fork(startFetchChatsOnHold);
}

function* fetchChatsRequestSaga() {
  yield fork(startFetchChatsRequestSaga);
}

function* fetchChatsSearchSaga() {
  const { activeChatSide }: ChatsState = yield select(state => state.chats);
  if (activeChatSide === ChatSideType.ON_HOLD)
    yield fork(startFetchChatsOnHold);
  if (activeChatSide === ChatSideType.REQUEST)
    yield fork(startFetchChatsRequestSaga);
}

function* fetchChatsSearchAdvancedSaga() {
  const { contactName = '' } = yield select(activeSideFilters);
  yield put(fetchSearchChats(contactName));
}

function* increaseOffsetChatsOnHoldSaga() {
  const { totalFilteredChatsOnHold, tray }: ChatsState['chatsOnHold'] =
    yield select((state: RootState) => state.chats.chatsOnHold);
  const qtyChatsOnHold = tray?.filter(({ show = false }) => show)?.length;
  if (totalFilteredChatsOnHold > qtyChatsOnHold)
    yield fork(startFetchChatsOnHold);
}

function* increaseOffsetChatsRequestSaga() {
  const { totalFilteredChatRequests, tray }: ChatsState['chatsRequest'] =
    yield select((state: RootState) => state.chats.chatsRequest);
  const qtyChatsRequest = tray?.filter(({ show = false }) => show)?.length;
  if (totalFilteredChatRequests > qtyChatsRequest)
    yield fork(startFetchChatsRequestSaga);
}

export default function* chatsSaga() {
  yield takeLeading(fetchChatsOnHold, fetchChatsOnHoldSaga);
  yield takeLeading(increaseOffsetChatsOnHold, increaseOffsetChatsOnHoldSaga);
  yield takeLeading(fetchChatsRequest, fetchChatsRequestSaga);
  yield takeLeading(increaseOffsetChatsRequest, increaseOffsetChatsRequestSaga);
  yield takeLatest(fetchSearchChats, fetchChatsSearchSaga);
  yield takeLatest(fetchTransformTextAI, fetchTransformTextAISaga);
  yield debounce(
    300,
    [chatSearchActions.changeChatsFilters, chatSearchActions.cleanChatsFilters],
    fetchChatsSearchAdvancedSaga
  );
  yield takeLatest(fetchCRMbutton, fetchCRMbuttonSaga);
  yield takeLatest(fetchCRMinfo, fetchCRMinfoSaga);
  yield takeLatest(fetchNotificationTimeLimit, fetchNotificationTimeLimitSaga);
  yield takeLatest(fetchSegments, fetchStartConsoleSaga);
}
